import axios from '@axios';
import qs from 'qs';
import _ from 'lodash';

export function getParks(query) {
    let queryString = qs.stringify(query);
    return axios.get('/parking-lots?' + queryString);
}
export function importCsv(data, options) {
    let formData = new FormData();
    formData.append('files.files', data);
    formData.append('data', JSON.stringify({ strapi: true }));
    return axios.post('/parking-lots/import-csv', formData, options);
}
